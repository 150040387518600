@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

// Import des styles Material
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-icons/iconfont/material-icons.css';

.hammersmith-one-regular {
    font-family: "Hammersmith One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.container{
    max-width: 1500px;
    margin: auto;
    // border: solid 1px #000;
}